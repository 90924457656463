import React from "react"
import { graphql } from "gatsby"
import ImageHeroCut from "../components/landingpage/ImageHeroCut"
import Layout from "../components/Layout"
import Meta from "../components/Meta"
import ContentfulText from "../components/contentful/ContentfulText"

const Service = ({ data }) => {
  const pageData = data.contentfulService

  const heading = pageData.serviceHeading.serviceHeading.split("__")

  return (
    <Layout>
      <Meta
        title={pageData.title}
        description={pageData.serviceHeading.serviceHeading.replace("__", "")}
      />
      {pageData?.serviceImage?.gatsbyImage && (
        <ImageHeroCut
          title={
            heading[0] !== undefined
              ? heading[0]
              : pageData.serviceHeading.serviceHeading
          }
          titleEmphasis={heading[1] !== undefined ? heading[1] : ""}
          image={
            pageData.serviceImage.gatsbyImage
          }
          text={pageData.serviceOverview.serviceOverview}
        />
      )}
      {pageData.components !== null &&
        pageData.components.map(component => (
          <ContentfulText key={component.id} {...component} />
        ))}
    </Layout>
  )
}

export const data = graphql`
  query($ContenfulId: String!) {
    contentfulService(id: { eq: $ContenfulId }) {
      title
      serviceHeading {
        serviceHeading
      }
      serviceOverview {
        serviceOverview
      }
      serviceImage {
        gatsbyImage(
          layout: CONSTRAINED
          aspectRatio: 1
          placeholder: BLURRED
          formats: [AUTO, WEBP]
          width: 1200
          height: 1200
        )
      }
      components {
        ...ContentfulTextFragment
      }
    }
  }
`

export default Service

import React from "react"
import {
  Box,
  Link,
  Text,
  Heading,
  UnorderedList,
  OrderedList,
  ListItem,
  useColorModeValue,
  chakra,
} from "@chakra-ui/react"
import { graphql, Link as GatsbyLink } from "gatsby"
import Container from "../Container"

const BullseyeBlockQuote = ({ children }) => (
  <chakra.blockquote
    bg={useColorModeValue("gray.300", "gray.700")}
    my={2}
    mx="auto"
    fontStyle="italic"
    maxWidth="2xl"
    borderColor="bullseyeOrange.500"
    borderLeftWidth={4}
  >
    {children}
  </chakra.blockquote>
)

const createSpan = (spanProps, textValue, index) => {
  return (
    <chakra.span key={index} {...spanProps}>
      {textValue}
    </chakra.span>
  )
}

const createExternalLink = (uri, linkText, index) => {
  return (
    <Link href={uri} key={index} color="bullseyeOrange.500">
      {linkText}
    </Link>
  )
}

const createGatsbyLink = (uri, linkText, index) => {
  return (
    <Link as={GatsbyLink} to={uri} key={index} color="bullseyeOrange.500">
      {linkText}
    </Link>
  )
}

const createLink = (linkComponent, index) => {
  if (linkComponent.data?.uri?.startsWith("/")) {
    return createGatsbyLink(
      linkComponent.data.uri,
      combineTextComponents(linkComponent.content),
      index
    )
  } else if (linkComponent.data?.uri?.startsWith("http")) {
    return createExternalLink(
      linkComponent.data.uri,
      combineTextComponents(linkComponent.content),
      index
    )
  }
}

const combineTextComponents = textComponents => {
  let combinedText = []
  textComponents.forEach((textComponent, index) => {
    if (textComponent.nodeType === "text") {
      if (textComponent.marks.length > 0) {
        let spanProps = {}
        textComponent.marks.forEach(mark => {
          if (mark.type === "bold") {
            spanProps = { ...spanProps, fontWeight: "bold" }
          } else if (mark.type === "italic") {
            spanProps = { ...spanProps, fontStyle: "italic" }
          } else if (mark.type === "underline") {
            spanProps = { ...spanProps, textDecoration: "underline" }
          }
        })

        combinedText.push(createSpan(spanProps, textComponent.value, index))
      } else {
        combinedText.push(textComponent.value)
      }
    } else if (textComponent.nodeType === "hyperlink") {
      combinedText.push(createLink(textComponent, index))
    }
  })

  return combinedText
}

const renderComponent = (component, headingColor, index) => {
  if (component.nodeType === "paragraph") {
    return (
      <Text key={index} fontSize="xl" py={2}>
        {combineTextComponents(component.content)}
      </Text>
    )
  }
  if (component.nodeType === "heading-3") {
    return (
      <Heading key={index} as="h3" py={2} color={`${headingColor}.500`}>
        {combineTextComponents(component.content)}
      </Heading>
    )
  }
  if (component.nodeType === "heading-4") {
    return (
      <Heading key={index} as="h4" py={2} color={`${headingColor}.500`}>
        {combineTextComponents(component.content)}
      </Heading>
    )
  }
  if (component.nodeType === "unordered-list") {
    return (
      <UnorderedList key={index} textAlign="left" maxWidth="sm" mx="auto">
        {component.content.map((item, itemIndex) => {
          return (
            <ListItem key={itemIndex}>
              {renderComponent(item.content[0])}
            </ListItem>
          )
        })}
      </UnorderedList>
    )
  }
  if (component.nodeType === "ordered-list") {
    return (
      <OrderedList key={index} textAlign="left" maxWidth="sm" mx="auto">
        {component.content.map((item, itemIndex) => {
          return (
            <ListItem key={itemIndex}>
              {renderComponent(item.content[0])}
            </ListItem>
          )
        })}
      </OrderedList>
    )
  }
  if (component.nodeType === "blockquote") {
    return (
      <BullseyeBlockQuote key={index}>
        {component.content.map((item, itemIndex) => {
          return (
            <React.Fragment key={itemIndex}>
              {renderComponent(item)}
            </React.Fragment>
          )
        })}
      </BullseyeBlockQuote>
    )
  }
  return <React.Fragment key={index}></React.Fragment>
}

const ContentfulTextComponents = ({ textJson, headingColor }) => {
  const components = JSON.parse(textJson)

  if (!components.content) {
    return <></>
  }

  return components.content.map((component, index) => {
    return renderComponent(component, headingColor, index)
  })
}

const ContentfulText = ({
  id,
  heading,
  headingColor,
  text,
  extraMargin = 20,
  sectionTextAlign = `center`,
  ...rest
}) => {
  let rawText = false
  if (text !== undefined && text.raw !== undefined) {
    rawText = text.raw
  }

  return (
    <Box pt={50} width={`100%`} key={id} {...rest}>
      <Container mt="0" pt={4} maxWidth="800px" minHeight={0}>
        <Box textAlign={sectionTextAlign} mb={extraMargin}>
          <Heading as="h2" color={`${headingColor}.500`} mb={4}>
            {heading}
          </Heading>
          {rawText && (
            <ContentfulTextComponents
              textJson={rawText}
              headingColor={headingColor}
            />
          )}
        </Box>
      </Container>
    </Box>
  )
}

export default ContentfulText

export const query = graphql`
  fragment ContentfulTextFragment on ContentfulTextComponent {
    __typename
    id
    heading
    headingColor
    text {
      raw
    }
  }
`
